import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { Box, css } from 'theme-ui'
import Avatar from '@components/Avatar'
import Hero from '../../../../../content/assets/hero.svg'

const styles = {
  wrapper: {
    display: [`none`, null, `block`],
    position: `relative`,
    textAlign: `center`
  },
  hero: {
    width: '320px',
    height: '320px'
  },
  pattern: {
    height: `1/2`,
    transform: `translate(0, 50%)`
  }
}

export default () => {
  // const data = useStaticQuery(heroQuery)
  // const image = data && data.avatar && data.avatar.childImageSharp

  return (
    <Box sx={styles.wrapper}>
      <Hero
        alt="woman sitting at table studying"
        css={css(styles.hero)}
      />
    </Box>
  )
}

// const heroQuery = graphql`
//   query HeroQueryAndHeroQuery {
//     avatar: file(absolutePath: { regex: "/hero.(jpeg|jpg|gif|png)/" }) {
//       childImageSharp {
//         regular: gatsbyImageData(
//           width: 320
//           placeholder: TRACED_SVG
//         )
//       }
//     }
//   }
// `
